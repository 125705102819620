/* eslint-disable import/no-extraneous-dependencies */
import { FileModel } from "@aecom/core";
import { Component, Input, OnInit } from "@angular/core";
import { ITransmittalDocumentDownload, ITransmittalListItem } from "@api";
import TransmittalFileType from "@models/TransmittalFileType";
import TransmittalStatus from "@models/TransmittalStatus";
import LocalTransmittalDetailsService from "@services/local-transmittalDetails.service";
import LocalTransmittalDocumentTypeService from "@services/local-transmittalDocumentType.service";
import { documentTypeTransmittalId } from "@shared/staticValue";
import { getDate, getFilesByType } from "@shared/utils";

@Component({
  selector: "transmittalDetail",
  templateUrl: "./transmittalDetail.component.html",
  styleUrls: ["./transmittalDetail.component.scss"],
})
export default class TransmittalDetailComponent implements OnInit {
  @Input() transmittal: ITransmittalListItem;

  @Input() documents: ITransmittalDocumentDownload[];

  @Input() isDBAdmin = false;

  maxCharacters = 30;

  orignator: string;

  recipent: string;

  reference: string;

  subject: string;

  transmittalFile: FileModel[] = [];

  attachments: FileModel[] = [];

  letterDate: string;

  receivedDate: string;

  dueDate: string;

  organizationReferenceNumber: string;

  documentType: string;

  transmittalDetails: string;

  note: string;

  isLoaded = false;

  static getFileIcon(type: string): string {
    return type.includes("image")
      ? " icon icon-image_doc"
      : " icon icon-blank_doc";
  }

  constructor(public localTransmittalDocumentTypeService: LocalTransmittalDocumentTypeService, public localTransmittalDetailsService: LocalTransmittalDetailsService){}

  // eslint-disable-next-line sonarjs/cognitive-complexity
  ngOnInit(): void {
    const none = "N/A";
    if(this.transmittal.transmittal_create?.correspondence_reference_user_correspondence_reference_userTotransmittal_create_From)
    {
      const orignator = this.transmittal.transmittal_create.correspondence_reference_user_correspondence_reference_userTotransmittal_create_From;
      this.orignator= `${orignator.Name ?? "-"}, ${orignator.Title ?? "-"}, ${orignator.Organization ?? "-"}, ${orignator.Email ?? "-"}`;
    }
    else{
      this.orignator= none;
    }
    if(this.transmittal.transmittal_create?.correspondence_reference_user_correspondence_reference_userTotransmittal_create_To)
    {
      const recipent = this.transmittal.transmittal_create.correspondence_reference_user_correspondence_reference_userTotransmittal_create_To;
      this.recipent= `${recipent.Name ?? "-"}, ${recipent.Title ?? "-"}, ${recipent.Organization ?? "-"}, ${recipent.Email ?? "-"}`;
    }
    else{
      this.recipent= none;
    }

    this.reference= this.transmittal.transmittal_create?.Reference ?? none;
    this.subject= this.transmittal.transmittal_create.Subject ?? none;
    
    this.letterDate=this.transmittal.transmittal_create?.LetterDate ? getDate(this.transmittal.transmittal_create.LetterDate) : none;
    this.receivedDate= this.transmittal.ReceivedDate ? getDate(this.transmittal.ReceivedDate) : none;
    this.dueDate= this.transmittal.DueDate ? getDate(this.transmittal.DueDate) : none;
    this.documentType = this.localTransmittalDocumentTypeService.getItems().find((item)=>{return item.Guid === this.transmittal.transmittal_create.DocumentTypeId })?.Title ?? none;
    this.transmittalDetails = this.transmittal.transmittal_create.DocumentTypeId === documentTypeTransmittalId ? (this.localTransmittalDetailsService.getItems().find((item)=>{return item.Guid === this.transmittal.transmittal_create.TransmittalDetailId })?.Title ?? none) : null;
    this.organizationReferenceNumber= this.transmittal.transmittal_create.OrganizationReferenceNumber ?? none;
    this.note= this.transmittal.transmittal_create.Note ?? none;
    const documentsForThis = this.documents.filter((item)=>{return item.Status === TransmittalStatus.Draft});
    this.transmittalFile = getFilesByType(documentsForThis, TransmittalFileType.TransmittalFile);
    this.attachments = getFilesByType(documentsForThis, TransmittalFileType.Attachment)
    this.isLoaded = true;
  }
}
