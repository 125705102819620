/* eslint-disable import/no-extraneous-dependencies */
import { FileModel, ISelectItem, ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { TransmittalIssued, ITransmittalListItem, IUser } from "@api";
import { ITransmittalDocumentDownload } from "@api/model/iTransmittalDocumentDownload";
import RowItem from "@shared/selectUser/table-row";
import TransmittalStatus from "@models/TransmittalStatus";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import TransmittalRole from "@models/TransmittalRole";
import LoginUser from "@models/loginUser";
import LocalContractUserService from "@services/local-contractUser.service";
import { transmittalDBRoldId, transmittalDocControlRoleId, transmittalAppId, transmittalManagerRoleId } from "./staticValue";


export function getDateFormatted_MMDDYYYY(date: Date | string): string {
  const offset = new Date(date).getTimezoneOffset();
  const d = new Date(new Date(date).setUTCMinutes(offset));
  const year = d.getFullYear();
  const month = (1 + d.getMonth()).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");

  return `${month}/${day}/${year}`;
}

// export function mappingFiles(documents: ITransmittalDocumentDownload[]): FileModel[] {
//     return documents
//       .map((docs) => {
//         return new FileModel(docs.Guid,
//             docs.FileName,
//             docs.DateCreated,
//             docs.URL,
//             "preloaded",
//             0,
//             null,
//             docs.DownloadURL);
//       })
//       .sort((a, b) => {
//         return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
//       });
//   }

export function emailValidation(email: string | undefined): boolean {
  // eslint-disable-next-line security/detect-unsafe-regex
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && email.trim() !== "" ? re.test(email.toLowerCase()) : true;
}

export function zipValidation(zip: string | undefined): boolean {
  return zip && zip.trim() !== "" ? /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) : true;
}

export function isSystemFields(field: string): boolean {
  return (
    field === "Guid" ||
    field === "IsDraft" ||
    field === "DateCreated" ||
    field === "CreatedBy" ||
    field === "DateUpdated" ||
    field === "UpdatedBy" ||
    field === "DateDeleted" ||
    field === "DeletedBy"
  );
}

export function generateUserRowItem<T>(item: T & { UserInfo?: IUser }, extraProperties?: Partial<RowItem>): RowItem {
  let organization = "";

  if (item.UserInfo?.department) {
    const nameArray = item.UserInfo.department.split(" ");

    if (nameArray) {
      [organization] = nameArray;
    }
  }
  return {
    id: item.UserInfo.id,
    name: item.UserInfo.displayName ?? "",
    email: item.UserInfo.mail ?? "",
    organization,
    track: item.UserInfo.department ?? "",
    checked: false,
    ...extraProperties,
  };
}

export function combineUserInfo<T>(item: T, user?: IUser): T & { UserInfo?: IUser } {
  return { ...item, UserInfo: user };
}

export function getUsersDropDownByRoleId(users: IContractUserWithUserInfo[], roleId: string): ISelectItem[] {
  return users
    .filter((u) => {
      return u.contract_user_application_role.find((a) => {
        return a.ApplicationId === transmittalAppId && a.ApplicationRoleId === roleId;
      });
    })
    .map((u) => {
      return { id: u.UserId, name: u.UserInfo.displayName ?? "", checked: false };
    });
}

export function getAllUsersExceptDB(users: IContractUserWithUserInfo[]): RowItem[] {
  return users
    .filter((u) => {
      return u.contract_user_application_role.find((a) => {
        return a.ApplicationId === transmittalAppId && a.ApplicationRoleId !== transmittalDBRoldId;
      });
    })
    .map((user) => {
      const rfiRole = user.contract_user_application_role.filter((role) => {
        return role.ApplicationId === transmittalAppId;
      });
      const role = rfiRole.length > 0 ? rfiRole[0].application_role.Role : "User";
      return generateUserRowItem<IContractUserWithUserInfo>(user, {
        role
      });
    });
}

export function errorHandle(error: HttpErrorResponse, activeModal: ModalContainerService, router: Router): void {
  if (error.status === 403 || error.status === 409) {
    const modalInstance1 = activeModal.open(NotificationComponent);
    modalInstance1.instance.type = NotificationType.Information;
    modalInstance1.instance.theme = "light";
    modalInstance1.instance.title = "Your Changes Can't be Saved";
    modalInstance1.instance.body = "Someone already saved changes. Please refresh your screen to see updates.";
    modalInstance1.result.then((result1) => {
      if (result1 === 0) {
        const currentUrl = router.url;
        router.navigateByUrl("blank").then(() => {
          router.navigateByUrl(currentUrl);
        });
      }
    });
  } else {
    console.log(error);
    router.navigateByUrl(`${this.contractId}/list`);
  }
}

export function isFileNameInvalid(name: string): boolean {
  const specialChars = /[`!#$%^&*+=\[\]{};':"\\|,<>\/?~]/;
  return specialChars.test(name);
}

export function getDate(date?: string | null): string {
  if (!date) {
    return "-";
  }
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric"
  } as Intl.DateTimeFormatOptions;

  const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));

  return tempDate.toLocaleDateString(undefined, options).replace(", ", "-").replace(" ", "-");
}

export function getFormatedDate(date: string): string {
  return `${new Date(date)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric"
    })
    .replace(", ", "-")
    .replace(" ", "-")} ${new Date(date).toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit"
  })}`;
}

export function getStatusText(status: string, isDB = false): string {
  switch (status) {
    case TransmittalStatus.Draft:
    case TransmittalStatus.Filed:
    case TransmittalStatus.Responded:
    case TransmittalStatus.Response_Requested:
      return status;
    case TransmittalStatus.In_Review:
    case TransmittalStatus.Issued:
      return isDB ? TransmittalStatus.Issued : status;
    default:
      return status;
  }
}

function getIconStr(iconname: string): string {
  return `icon icon-purple icon-lg ${iconname}`;
}

export function getIcon(status, isDB = false): string | null {
  switch (status) {
    case TransmittalStatus.Draft:
      return getIconStr("icon-action_write");
    case TransmittalStatus.Submitted:
    case TransmittalStatus.Issued:
      return getIconStr("icon-action_send");
    case TransmittalStatus.In_Review:
      return isDB ? getIconStr("icon-action_send") : getIconStr("icon-action_rotate_anticlockwise");
    case TransmittalStatus.Responded:
      return getIconStr("icon-action_special");
    case TransmittalStatus.Filed:
      return getIconStr("icon-action_check_circle");
    default:
      return null;
  }
}

export function isFinalStep(status: string): boolean {
  return status === TransmittalStatus.Responded || status === TransmittalStatus.Filed;
}

export function getFilesByType(documents: ITransmittalDocumentDownload[], documentType?: string): FileModel[] {
  return documents
    .filter((item) => {
      return documentType ? item.FileType === documentType : true;
    })
    .map((docs) => {
      return new FileModel(
        docs.Guid,
        docs.FileName,
        docs.DateCreated,
        docs.URL,
        "preloaded",
        0,
        null,
        docs.DownloadURL,
        docs.Size
      );
    })
    .sort((a, b) => {
      return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
    });
}

export function getDateWithOffSet(date: string | null): string {
  if (!date) return null;
  const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
  return tempDate.toLocaleDateString();
}

export function getNavigationUrl(item: ITransmittalListItem): string {
  let key = "view";
  if (item.ViewOnly !== true) {
    switch (item.Status) {
      case TransmittalStatus.Draft:
        key = "create";
        break;
      case TransmittalStatus.In_Review:
        key = "inReview";
        break;
      case TransmittalStatus.Submitted:
      case TransmittalStatus.Issued:
        key = "issued";
        break;
      default:
        break;
    }
  }
  return `${item.ContractId}/${key}/${item.Guid}`;
}

export function validDBViewPermission(items: ITransmittalListItem[], localContractUserService: LocalContractUserService, user: LoginUser): ITransmittalListItem[] {
  const contractRole = localContractUserService.currentUserContractRole;
  if (contractRole === TransmittalRole.DBAdmin) {
    return items.filter((item) => {
      const fromId =
        item.transmittal_create
          ?.correspondence_reference_user_correspondence_reference_userTotransmittal_create_From
          ?.UserId;
      const fromUserRole = localContractUserService.getUserRoleById(fromId);
      const toId =
        item.transmittal_create
          ?.correspondence_reference_user_correspondence_reference_userTotransmittal_create_To
          ?.UserId;
      const toUserRole = localContractUserService.getUserRoleById(toId);
      const createBy = item.transmittal_create?.UpdatedBy;
      const originatorUserRole = localContractUserService.getUserRoleById(createBy);
      return fromUserRole === TransmittalRole.DBAdmin || toUserRole === TransmittalRole.DBAdmin || originatorUserRole === TransmittalRole.DBAdmin;
    });
  } else if(contractRole === TransmittalRole.SA_User)
  {
    return items.filter((item) => {
      return item.transmittal_cc_user && item.transmittal_cc_user.map((cc) => cc.UserId).includes(user.Id);
    });
  }
  return items;
}

export function getLastestIssuedItem(item?: ITransmittalListItem): TransmittalIssued | null {
  if(item?.transmittal_issued.length > 0)
  {
    return item.transmittal_issued.sort(
      (a, b) =>
        new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
    )[0];
  } 
  return null;
}

export function convertDateToUTCIOSString(date: Date | string): string {
  return new Date(new Date(date).setUTCHours(0)).toISOString();
}

export function isValidUUID(id: string): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
}

export function validateInput(input: string): boolean {
  if(input?.trim() === "")
  {
    return true;
  }
  const latin1Pattern = /^[ -~\u00A0-\u00FF\n]+$/;

  if (!latin1Pattern.test(input)) {
      alert(`Invalid character input: ${input}`);
      return false;
  }
  return true;
}

export function getKeyWordsArray(values?: string): string[] {
  if (values) {
    return values.trim().split(",").sort();
  }
  return [];
}