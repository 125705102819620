import { ITransmittalIssuedStepItem, ITransmittalListItem } from "@api";
import { getLastestIssuedItem } from "@shared/utils";
import TransmittalUpdateBase from "./TransmittalUpdateBase";
import TransmittalIssueBase from "./transmittalIssueBase";

export default class TransmittalIssue
  extends TransmittalUpdateBase
  implements ITransmittalIssuedStepItem
{
  public transmittal_issued: TransmittalIssueBase;

  public transmittal_cc_user?: string[];

  constructor(contractId: string, item?: ITransmittalListItem) {
    super(contractId, item);

    const lastIssued = getLastestIssuedItem(item);
    this.transmittal_issued = lastIssued
      ? new TransmittalIssueBase(lastIssued)
      : new TransmittalIssueBase();

    if (item.transmittal_cc_user) {
      this.transmittal_cc_user = item.transmittal_cc_user
        .filter((cc) => {
          return cc.DeletedBy === null;
        })
        .map((user) => {
          return user.UserId;
        });
    } else {
      this.transmittal_cc_user = [];
    }
  }
}
