import { ITransmittalCreate, ITransmittalCreateInput } from "@api";

import { defaultDocumentTypeIdForDB } from "@shared/staticValue";
import TimeStampWithDraft from "./timeStampWithDraft";

export default class TransmittalCreateBase
  extends TimeStampWithDraft
  implements ITransmittalCreateInput
{
  public Guid?: string;

  public LetterDate?: string | null;

  public Reference?: string | null;

  public Subject?: string | null;

  public From?: string | null;

  public To?: string | null;

  public DocumentTypeId?: string | null;

  public TransmittalDetailId?: string | null;

  public OrganizationReferenceNumber?: string | null;

  public Note?: string | null;

  public Priority?: number | null;

  constructor(isDB: boolean, item?: ITransmittalCreate, dbUserId?: string) {
    super(item);
    this.Guid = item?.Guid;
    this.LetterDate = item?.LetterDate;
    this.Reference = item?.Reference;
    this.Subject = item?.Subject;
    this.From = item ? item.From : dbUserId;
    this.To = item?.To;
    this.DocumentTypeId = isDB
      ? defaultDocumentTypeIdForDB
      : item?.DocumentTypeId;
    this.TransmittalDetailId = item?.TransmittalDetailId;
    this.OrganizationReferenceNumber = item?.OrganizationReferenceNumber;
    this.Note = item?.Note;
    this.Priority = item?.Priority;
  }
}
