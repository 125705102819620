<div class="maincontainer" *ngIf="transmittal">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="docs"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                innerClass="btn lg outline orange"
                (click)="back()"
                data-cy="back"
                >back</aecom-button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="1"
              [title]="title"
              style="margin-bottom: 40px"
              *ngIf="!transmittal.showResult"
            >
              <div
                class="text-center"
                style="padding-top: 35px"
              >
                <div>
                  <i class="icon icon-action-basic_todo"></i>
                  <div style="color: #aaa; padding-top: 10px">
                    {{clipboardText}}
                  </div>
                </div>
              </div>
            </aecom-form-group>
            <div
              class="text-center"
              style="padding-top: 35px; margin-bottom: 40px; grid-column: span 6;"
              *ngIf="transmittal.showResult && transmittal.noResponse"
            >
              <div>
                <i class="icon icon-action-basic_todo"></i>
                <div style="color: #aaa; padding-top: 10px">
                  FILED
                </div>
              </div>
            </div>
            <aecom-form-group
              columnSpan="6"
              columns="2"
              [title]="title"
              style="margin-bottom: 40px"
              *ngIf="transmittal.showResult && !transmittal.noResponse"
            >
              <div style="grid-column: span 2; display: flex; margin-top: 5px;">
                <div>{{transmittal.replyBy}}</div>
                <div style="margin-left: auto;">{{transmittal.replyDate}}</div>
              </div>
              <aecom-form-item label="Letter Date" columnSpan="1">
                {{transmittal.letterDate}}
              </aecom-form-item>
              <aecom-form-item label="Reference ID" columnSpan="1">
                {{transmittal.referenceId}}
              </aecom-form-item>
              <aecom-form-item
                label="Transmittal File"
                columnSpan="1"
              >
                <file-list
                *ngIf="transmittal.transmittalFile && transmittal.transmittalFile.length > 0; else notAvailable"
                  [fileList]="transmittal.transmittalFile"
                  [allowMovingFiles]="false"
                ></file-list>
              </aecom-form-item>
              <aecom-form-item
                label="Attachments"
                columnSpan="1"
              >
                <file-list
                *ngIf="transmittal.attachments && transmittal.attachments.length > 0; else notAvailable"
                  [fileList]="transmittal.attachments"
                  [allowMovingFiles]="false"
                ></file-list>
              </aecom-form-item>
              <aecom-form-group
                columnSpan="2"
                columns="1"
                title="CC"
                style="margin-top: 40px"
              >
                <div style="width: 100%; height: 250px" class="noTableFilter">
                  <aecom-table
                    [ngValue]="transmittal.ccUsersTableData"
                    (ngValueChange)="transmittal.ccUsersTableRows = $event"
                    [theme]="'light'"
                    innerClass="list"
                  >
                    
                      <tr
                        *ngFor="let bRow of transmittal.ccUsersTableRows"
                        class="assignUser_tr_hover"
                      >
                        <ng-container *ngFor="let hCol of tableHeader">
                          <td *ngIf="hCol.Type !== columnType.Hide">
                            <span class="assignUser_td_Item">
                              {{ bRow[hCol.Name] }}
                            </span>
                          </td>
                        </ng-container>
                      </tr>
                    
                  </aecom-table>

                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                    "
                    *ngIf="transmittal.ccUsersTableData?.length === 0"
                  >
                    <div class="noItem-body">
                      <div>
                        <div
                          class="
                            noItem-icon-row
                            icon
                            icon-social_user_team_big
                            icon-grey icon-xl
                          "
                        ></div>
                      </div>

                      <div class="noItem-title-row">None</div>
                    </div>
                  </div>
                </div>
                
              </aecom-form-group>
            </aecom-form-group>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>
