// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const environment = {
  production: false,
  baseWebAPI: "https://dev.api-pmis.bbjnyc.com/api/v1",
  azureNew: {
    clientId: "f1082fce-3bd7-4576-85fe-2463ef18dcd2",
    tenantId: "bfb413d6-9a23-4cd3-a58d-e141aaf2948a",
    name: "bbjnyc.dev.portal.client",
    scope: "api://f1082fce-3bd7-4576-85fe-2463ef18dcd2/Read",
  },
  url: {
    portal: "https://dev.portal.bbjnyc.com",
    userSetting: "https://dev.settings.bbjnyc.com",
    rfi: "https://dev.rfi.bbjnyc.com",
    submittal: "https://dev.submittal.bbjnyc.com",
    correspondence: "https://dev.correspondence.bbjnyc.com",
    transmittal: "https://dev.transmittal.bbjnyc.com",
    redirectUri: "https://dev.transmittal.bbjnyc.com",
    hostURL: "https://bbjnyc.com",
    logoutURL: "https://dev.transmittal.bbjnyc.com/sign-out",
  },
  api: {
    listUsers:
      "https://prod-89.eastus.logic.azure.com/workflows/f2a2a684af4143488f09b73a374dad00/triggers/manual/paths/invoke",
    getUser:
      "https://prod-72.eastus.logic.azure.com/workflows/7f3c85d22ab247ba81af029deef755bd/triggers/manual/paths/invoke",
  },
  stage: "development",
  fileService: "azure",
};
export default environment;
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
