<div
  class="maincontainer"
>
  <section class="contentcontainer">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <div class="header-title">
            <h1 class="title">TRANSMITTAL LIST</h1>
            <span>{{ subtitle }}</span>
          </div>

          <div
            class="header-action"
            style="margin-left: auto"
          >
            <aecom-button innerClass="btn solid lg blue" (click)="download()">
              Download Log
            </aecom-button>
          </div>

          <div
            class="header-action"
            style="margin-left: 20px"
            *ngIf="showCreateBtn"
          >
            <aecom-button
              data-cy="new"
              innerClass="btn solid lg blue"
              (click)="createTransmittal()"
            >
              New Transmittal
            </aecom-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" [ngClass]="{ tablestyle: fixedheadercss }">
      <div class="col-12">
        <aecom-table
          [ngValue]="tableData"
          (ngValueChange)="tableRows = $event"
          [theme]="'light'"
          innerClass="list cus list-view-page"
          id="excel-table"
        >
          <div class="filters-section">
            <div class="d-flex">
              <div class="pr-15" style="width: 391px">
                <aecom-textbox
                  [theme]="'light'"
                  [(ngValue)]="searchText"
                  name="table-search"
                  placeholder="Search"
                  iconLeft="icon-basic_magnifier"
                  (keyupEnter)="filterData()"
                ></aecom-textbox>
              </div>
              <div class="pr-15">
                <aecom-dropdown
                  [theme]="'light'"
                  [data]="documentTypeFilter"
                  [placeholder]="'Document Type'"
                  [autocomplete]="true"
                  [(selectedId)]="documentTypeFilterId"
                  (ngValueChange)="changeDocumentTypeFilter($event)"
                ></aecom-dropdown>
              </div>
              <div class="pr-15">
                <aecom-dropdown
                  *ngIf="showOrgDD"
                  [theme]="'light'"
                  [data]="fromToFilter"
                  [placeholder]="'All Organization'"
                  [autocomplete]="true"
                  [(selectedId)]="fromToFilterId"
                  (ngValueChange)="changeFromToFilter($event)"
                ></aecom-dropdown>
              </div>
            </div>

          </div>

          <thead>
            <tr class="listRFI_tr">
              <th class="sort" id="TransmittalNumber" [ngStyle]="{'width.px':widthId}">Transmittal #</th>
              <th class="sort" id="OrganizationRef">Organization Ref.#</th>
              <th class="sort" id="Subject" style="width: 20%;">Subject/Title</th>
              <th class="sort" id="TransmittalDocType">Document Type</th>
              <th class="sort" id="TransmittalDetail">Transmittal Detail</th>

              <th class="sort" id="FromOrg">From</th>
              <th class="sort" id="ToOrg">To</th>
              <th class="sort" id="LetterDateSort" [ngStyle]="{'width.px':widthDate}">Issue Date</th>
              <!-- <th class="sort" id="ReceivedDateSort">Received Date</th>
              <th class="sort" id="DueDateSort">Due Date</th> -->
              <th class="sort" id="KeyWords" style="width: 15%;">Keywords</th>
              <th class="sort" id="Status">Step</th>
              <th class="sort no-print" id="response" [ngStyle]="{'width.px':widthAction}">Action</th>
            </tr>
          </thead>
          <tbody data-cy="tbody">
            <ng-container *ngFor="let row of tableRows">
              <tr *ngIf="!row.IsHidden">
                <td [ngStyle]="{'width.px':widthId}">{{ row.TransmittalNumber }}</td>
                <td>{{ row.OrganizationRef }}</td>
                <td style="width: 20%;">{{ row.Subject }}</td>
                <td>{{ row.TransmittalDocType }}</td>
                <td>{{ row.TransmittalDetail }}</td>
                <td>{{ row.FromOrg }}<div>{{ row.From}}</div></td>
                <td>{{ row.ToOrg }}<div>{{ row.To }}</div></td>
                <td [ngStyle]="{'width.px':widthDate}">{{ row.LetterDate }}</td>
                <!-- <td>{{ row.ReceivedDate }}</td>
                <td>{{ row.DueDate }}</td> -->
                <!-- <td>{{ row.KeyWords | titlecase }}</td> -->
                <td style="width: 15%;">
                  <div style="display: flex; flex-wrap: wrap">
                    <ng-container *ngFor="let tag of row.KeyWords">
                      <div style="position: relative; margin-right: 6px; margin-bottom: 6px;">
                        <div class="tag">
                          {{tag}}
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <div>
                    <i [class]="row.StatusIcon"></i>

                    <span data-cy="status">{{ row.StatusText }}</span>
                  </div>
                </td>

                <td class="no-print" [ngStyle]="{'width.px':widthAction}">
                  <aecom-button
                    *ngIf="!row.HideBtn"
                    data-cy="btn"
                    [innerClass]="row.BtnClass"
                    (click)="goTo(row.BtnUrl)"
                  >
                    {{ row.BtnText }}
                  </aecom-button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </aecom-table>
      </div>
    </div>
  </section>
</div>
