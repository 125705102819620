/* eslint-disable import/no-extraneous-dependencies */
import { Component, Input } from "@angular/core";
import { ITransmittalListItem, TransmittalIssued } from "@api";
import { getDateFormatted_MMDDYYYY, getKeyWordsArray, getLastestIssuedItem } from "@shared/utils";

@Component({
  selector: "transmittalProcessing",
  templateUrl: "./transmittalProcessing.component.html",
  styleUrls: ["./transmittalProcessing.component.scss"],
})
export default class TransmittalProcessingComponent {
  @Input() transmittal: ITransmittalListItem;

  item: TransmittalIssued;

  constructor(){}

  ngOnInit(): void {
    this.item = getLastestIssuedItem(this.transmittal);
  }

  getFormatedDate(date: string): string {
    return getDateFormatted_MMDDYYYY(date);
  }

  sortKeyWords(values?: string): string[] {
    return getKeyWordsArray(values);
  }

  
}
