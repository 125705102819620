enum TransmittalRole {
  DBAdmin = '8a013230-0baf-11ea-917a-027e1b1809fa',
  DocControl = '8a013231-0baf-11ea-917a-027e1b1809fa',
  Manager = '8a013232-0baf-11ea-917a-027e1b1809fa',
  CC = 'CC',
  PMC_User = '8a013233-0baf-11ea-917a-027e1b1809fa',
  SA_User = '8a013235-0baf-11ea-917a-027e1b1809fa',
}

export default TransmittalRole;
