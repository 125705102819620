// eslint-disable-next-line import/no-extraneous-dependencies
import { Component, Input, OnInit } from "@angular/core";
import { ITransmittalListItem } from "@api/model/iTransmittalListItem";
import { getIcon, getStatusText } from "@shared/utils";

@Component({
  selector: "transmittalHeader",
  templateUrl: "./transmittalHeader.component.html",
  styleUrls: ["./transmittalHeader.component.scss"],
})
export default class TransmittalHeaderComponent implements OnInit {
  @Input() transmittal: ITransmittalListItem;

  @Input() IsDbAdmin = false;

  number: string;

  icon: string;

  status: string;

  ngOnInit(): void {
    this.number = this.transmittal.TransmittalNumber;
    this.status = getStatusText(
      this.transmittal.Status,
      this.IsDbAdmin
    );
    this.icon = getIcon(
      this.transmittal.Status,
      this.IsDbAdmin
    );
  }
}