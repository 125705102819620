/* eslint-disable sonarjs/cognitive-complexity */
import {
  FileModel,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  NotificationType,
  UploadStatus,
} from "@aecom/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  TransmittalDocumentsService,
  TransmittalItemService,
  CorrespondenceReferenceUser,
  ITransmittalListItem,
  ITransmittalDocumentUploadReturn,
  ITransmittalDocumentDownload,
} from "@api";
import AuthService from "@auth/auth.service";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import ColumnType from "@models/columnType";
import TransmittalCreate from "@models/transmittalCreate";
import TransmittalDocumentUpload from "@models/transmittalDocumentUpload";
import TransmittalFileType from "@models/TransmittalFileType";
import TransmittalRole from "@models/TransmittalRole";
import TransmittalStatus from "@models/TransmittalStatus";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import IViewPrepare from "@models/IViewPrepaer";
import AzureBlobService from "@services/azureBlob.service";
import FileManagementService from "@services/fileManagement.service";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import tableHeader from "@shared/selectUser/table-header";
import TableHeaderCol from "@shared/selectUser/table-header-col";
import RowItem from "@shared/selectUser/table-row";
import {
  transmittalDBRoldId,
  transmittalDocControlRoleId,
  transmittalAppId,
  documentTypeTransmittalId,
} from "@shared/staticValue";
import {
  convertDateToUTCIOSString,
  errorHandle,
  generateUserRowItem,
  getDateWithOffSet,
  getFilesByType,
  isFileNameInvalid,
  validateInput,
} from "@shared/utils";
import _, { isString } from "lodash";
import { Observable } from "rxjs";
import environment from "src/environments/environment";
import LocalTransmittalDocumentTypeService from "@services/local-transmittalDocumentType.service";
import LocalTransmittalDetailsService from "@services/local-transmittalDetails.service";
import LocalUserService from "@services/local-user.service";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export default class CreateComponent implements OnInit, ComponentCanDeactivate {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  title: string;

  transmittal: TransmittalCreate;

  oldTransmittal: TransmittalCreate;

  attemptToIssue = false;

  fromList: ISelectItem[];

  toList: ISelectItem[];

  documentTypeList: ISelectItem[];

  transmittalDetailList: ISelectItem[];

  contractId: string;

  currentUser: string;

  transmittalFile: FileModel[] = [];

  attachments: FileModel[] = [];

  oldTransmittalFile: FileModel[] = [];

  oldAttachments: FileModel[] = [];

  transmittalFileNameValid = true;

  attachmentsNameValid = true;

  enableSaveDraft = false;

  LetterDate: Date;

  dueDate: Date | null = null;

  receivedDate: Date | null = null;

  today: Date;

  allUserTableRows: RowItem[] = [];

  ccUsersTableData: RowItem[] = [];

  ccUsersTableRows: RowItem[] = [];

  showSelectUser = false;

  tableRows: RowItem[] = [];

  firstOpen = true;

  tableHeader: TableHeaderCol[] = tableHeader;

  activeSave = false;

  columnType = ColumnType;

  subjectMaxLength = 100;

  referenceMaxLength = 150;

  notesMaxLength = 250;

  allTags: string[] = [];

  isDB = true;

  private removedFileIds: string[] = [];

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractuServices: LocalContractService,
    public activeModal: ModalContainerService,
    public transmittalItemService: TransmittalItemService,
    public transmittalDocumentsService: TransmittalDocumentsService,
    public fileManagementService: FileManagementService,
    public localContractUserService: LocalContractUserService,
    public localTransmittalDocumentTypeService: LocalTransmittalDocumentTypeService,
    public localTransmittalDetailsService: LocalTransmittalDetailsService,
    public localUserService: LocalUserService
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    const { refUsers, topics } =
      this.activatedRoute.snapshot.data.createStepData;
    this.allTags = topics;
    this.currentUser = this.authService.user.Id;
    this.contractId = this.localContractuServices.currentContract.Guid;
    this.today = new Date();
    const userRole = this.localContractUserService.currentUserContractRole;
    this.isDB = userRole === TransmittalRole.DBAdmin;
    this.allUserTableRows = this.localContractUserService
      .getItems()
      .filter((user) => {
        return user.contract_user_application_role.find((role) => {
          if (this.isDB) {
            return (
              role.ApplicationId === transmittalAppId &&
              role.ApplicationRoleId === transmittalDBRoldId
            );
          }
          return (
            role.ApplicationId === transmittalAppId &&
            role.ApplicationRoleId !== transmittalDocControlRoleId
          );
        });
      })
      .map((u) => {
        return generateUserRowItem<IContractUserWithUserInfo>(u);
      });
    this.documentTypeList = this.localTransmittalDocumentTypeService
      .getItems()
      .map((item) => {
        return { name: item.Title, id: item.Guid, checked: false };
      });
    this.transmittalDetailList = this.localTransmittalDetailsService
      .getItems()
      .map((item) => {
        return { name: item.Title, id: item.Guid, checked: false };
      }).sort((a, b)=>{
        return a.name.localeCompare(b.name);
      });
    const userEmail = this.authService.user.Email;
    if (data) {
      const { item, documents } = data;
      this.loadData(
        refUsers.sort(CreateComponent.compare),
        userEmail,
        item,
        documents,
      );
    } else {
      this.loadData(refUsers.sort(CreateComponent.compare), userEmail);
    }
    this.loadingService.stop();
  }

  static compare(
    a: CorrespondenceReferenceUser,
    b: CorrespondenceReferenceUser,
  ): number {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }

  loadData(
    users: CorrespondenceReferenceUser[],
    userEmail: string | null,
    transmittal?: ITransmittalListItem,
    documents?: ITransmittalDocumentDownload[],
  ): void {
    if (this.isDB) {
      const contractUsers = this.localContractUserService.getItems();
      const contractDBs: CorrespondenceReferenceUser[] = [];
      const contractAHJVs: CorrespondenceReferenceUser[] = [];
      users.forEach((u) => {
        const user = contractUsers.find((cu) => {
          return cu.UserId === u.UserId && cu.DeletedBy === null;
        });
        if (user) {
          const transmittalRole = user.contract_user_application_role.find(
            (cuar) => {
              return cuar.ApplicationId === transmittalAppId;
            },
          );
          if (transmittalRole) {
            if (transmittalRole.ApplicationRoleId === transmittalDBRoldId) {
              contractDBs.push(u);
            } else {
              contractAHJVs.push(u);
            }
          }
        }
      });
      this.fromList = this.mappingRefUsers(contractDBs);
      this.toList = this.mappingRefUsers(contractAHJVs);
    } else {
      this.fromList = this.mappingRefUsers(users);
      this.toList = [...this.fromList];
    }

    const dbUser = this.isDB
      ? users.find(
          (u) =>
            u.Email?.toLocaleLowerCase() === userEmail?.toLocaleLowerCase(),
        )
      : undefined;
    this.oldTransmittal = new TransmittalCreate(
      this.contractId,
      this.isDB,
      transmittal,
      dbUser?.Guid,
    );
    this.dueDate = this.oldTransmittal.transmittal_issued?.DueDate
      ? new Date(
          getDateWithOffSet(this.oldTransmittal.transmittal_issued.DueDate),
        )
      : null;
    this.receivedDate = this.oldTransmittal.transmittal_issued?.ReceivedDate
      ? new Date(
          getDateWithOffSet(
            this.oldTransmittal.transmittal_issued.ReceivedDate,
          ),
        )
      : null;
    this.LetterDate = this.oldTransmittal.transmittal_create?.LetterDate
      ? new Date(
          getDateWithOffSet(this.oldTransmittal.transmittal_create.LetterDate),
        )
      : null;
    if (this.LetterDate) {
      this.oldTransmittal.transmittal_create.LetterDate =
        this.LetterDate.toISOString();
    }

    if (documents && documents.length > 0) {
      const documnetsForThis = documents.filter((item) => {
        return item.Status === TransmittalStatus.Draft;
      });
      console.log("all documents", documnetsForThis);

      this.oldTransmittalFile = getFilesByType(
        documnetsForThis,
        TransmittalFileType.TransmittalFile,
      );
      this.transmittalFile = JSON.parse(
        JSON.stringify(this.oldTransmittalFile),
      );
      this.oldAttachments = getFilesByType(
        documnetsForThis,
        TransmittalFileType.Attachment,
      );
      this.attachments = JSON.parse(JSON.stringify(this.oldAttachments));
    }
    this.title = this.oldTransmittal.Guid
      ? "Draft Transmittal "
      : "Create New Transmittal";
    this.ccUsersTableData = this.allUserTableRows.filter((u) => {
      return this.oldTransmittal.transmittal_cc_user.includes(u.id);
    });
    console.log(this.oldTransmittal);
    this.transmittal = JSON.parse(JSON.stringify(this.oldTransmittal));
  }

  mappingRefUsers(users: CorrespondenceReferenceUser[]): ISelectItem[] {
    const contractCode = this.localContractuServices.currentContract.Code;
    const isPW = contractCode.includes("PW-");
    const adUsers = this.localUserService.getItems();
    return users
      .filter((u) => {
        return !isPW || u.ORG === "AHJV" || u.ORG === "DDC";
      })
      .map((item: CorrespondenceReferenceUser) => {
        let name = item.Name;
        if (!item.UserId || item.UserId === "") {
          name = `${item.Name} (Offline)`;
        } 
        else if(adUsers.find((u)=>{return u.id===item.UserId})?.accountEnabled !== true)
        {
          name = `${item.Name} (Offline)`;
        }
        return {
          name: `${name}, ${item.Title ?? "-"}, ${item.Organization ?? "-"}`,
          id: item.Guid,
          checked: false,
        };
      });
  }

  // eslint-disable-next-line class-methods-use-this
  get transmittalFileType(): typeof TransmittalFileType {
    return TransmittalFileType;
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldTransmittal);
    const current = JSON.stringify(this.transmittal);
    const oldTransmittalFile = JSON.stringify(this.oldTransmittalFile);
    const currentTransmittalFile = JSON.stringify(this.transmittalFile);
    const oldAttachments = JSON.stringify(this.oldAttachments);
    const attachments = JSON.stringify(this.attachments);

    return (
      old !== current ||
      oldTransmittalFile !== currentTransmittalFile ||
      oldAttachments !== attachments
    );
  }

  removeUserClick(row: RowItem): void {
    this.ccUsersTableData = this.ccUsersTableData.filter(
      (reviewer) => reviewer.id !== row.id,
    );
    this.transmittal.transmittal_cc_user = this.ccUsersTableData.map((u) => {
      return u.id;
    });
    this.enableSaveDraft = this.hasChanges();
  }

  openWindowClick(): void {
    this.activeSave = false;

    this.tableRows = this.allUserTableRows
      .filter((user) => {
        return !this.transmittal.transmittal_cc_user.includes(user.id);
      })
      .map((user) => {
        user.checked = false;
        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.firstOpen || !e) {
      this.firstOpen = false;
    } else if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        this.ccUsersTableData = this.ccUsersTableData.concat(e);
        this.transmittal.transmittal_cc_user = this.ccUsersTableData.map(
          (u) => {
            return u.id;
          },
        );

        this.firstOpen = true;
        this.showSelectUser = false;
        this.enableSaveDraft = this.hasChanges();
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          this.firstOpen = true;
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.firstOpen = true;
      this.showSelectUser = false;
    }
  }

  IsInputValid(): boolean {
    const inValid = Object.values(this.transmittal.transmittal_create).find(
      (v) => {
        return (
          isString(v) && v !== null && v !== undefined && !validateInput(v)
        );
      },
    );
    return inValid === undefined;
  }

  IsValid(): boolean {
    if (
      !this.transmittal.transmittal_create.From ||
      this.transmittal.transmittal_create.From.trim() === "" ||
      !this.transmittal.transmittal_create.To ||
      this.transmittal.transmittal_create.To.trim() === "" ||
      !this.transmittal.transmittal_create.DocumentTypeId ||
      !this.transmittal.transmittal_create.LetterDate ||
      this.transmittal.transmittal_create.LetterDate.trim() === "" ||
      !this.transmittal.transmittal_create.Reference ||
      this.transmittal.transmittal_create.Reference.trim() === "" ||
      this.transmittal.transmittal_create.Reference.trim().length >
        this.referenceMaxLength ||
      !this.transmittal.transmittal_create.Subject ||
      this.transmittal.transmittal_create.Subject.trim() === "" ||
      this.transmittal.transmittal_create.Subject.trim().length >
        this.subjectMaxLength ||
      (this.transmittal.transmittal_create?.Note &&
        this.transmittal.transmittal_create.Note.trim().length >
          this.notesMaxLength) ||
      this.transmittalFile.length === 0 ||
      !this.isFileNameValid()
    ) {
      return false;
    }
    return true;
  }

  submit(): void {
    this.attemptToIssue = true;

    if (!this.IsInputValid()) {
      return;
    }

    const validation = this.IsValid();

    if (!validation) {
      return;
    }

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Submit Transmittal?";
      modalInstance.instance.body =
        "Once Transmittal is submitted, you will no longer be able to edit.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.transmittal.transmittal_create.IsDraft = false;
          if (!this.isDB && this.transmittal.transmittal_issued) {
            this.transmittal.transmittal_issued.IsDraft = false;
          }
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges()) return;

    if (!this.IsInputValid()) {
      return;
    }

    if (!this.isFileNameValid()) return;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save Draft?";
      modalInstance.instance.body = "Your Transmittal will save as draft.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.transmittal.transmittal_create.IsDraft = true;
          if (!this.isDB && this.transmittal.transmittal_issued) {
            this.transmittal.transmittal_issued.IsDraft = true;
          }
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();

    if (!this.isDB && this.transmittal.transmittal_issued) {
      if (this.transmittal.transmittal_issued.ReceivedToday) {
        this.transmittal.transmittal_issued.ReceivedDate = null;
      }
      if (!this.transmittal.transmittal_issued.RequireResponse) {
        this.transmittal.transmittal_issued.DueDate = null;
      }
    } else {
      delete this.transmittal.transmittal_issued;
    }
    if (!this.showTransmittalDetail()) {
      this.transmittal.transmittal_create.TransmittalDetailId = null;
    }
    console.log(this.transmittal);

    this.transmittalItemService.transmittalUpdate(this.transmittal).subscribe(
      async (r) => {
        await this.updateFiles();
        this.refreshEntity();
        this.loadingService.stop();
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.refreshEntity();
        this.loadingService.stop();
        errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  refreshEntity(): void {
    this.oldTransmittal = this.transmittal;
    this.oldTransmittalFile = this.transmittalFile;
    this.oldAttachments = this.attachments;
  }

  async updateFiles(): Promise<void> {
    const allCurrentFiles = [...this.transmittalFile, ...this.attachments];
    const allOldFiles = [
      ...this.oldTransmittalFile.map((f) => f.Guid),
      ...this.oldAttachments.map((f) => f.Guid),
    ];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];
    const addIds = filesToAdd.map((f) => {
      return f.Guid;
    });
    console.log("service: filesToAdd", filesToAdd);
    console.log("service: fileIds", fileIds);
    console.log("service: filesToRemove", filesToRemove);
    console.log("service: removeIds", removeIds);

    if (addIds.length) {
      await this.transmittalDocumentsService
        .setTransmittalDocumentAsSuccessful(addIds)
        .subscribe();
    }

    if (removeIds.length) {
      await this.transmittalDocumentsService
        .removeTransmittalDocument(removeIds)
        .subscribe();
    }

    console.log("finished");

    return null;
  }

  getSubjectLength(): number {
    return this.transmittal.transmittal_create?.Subject
      ? this.transmittal.transmittal_create?.Subject.length
      : 0;
  }

  getReferenceLength(): number {
    return this.transmittal.transmittal_create?.Reference
      ? this.transmittal.transmittal_create?.Reference.length
      : 0;
  }

  getNotesLength(): number {
    return this.transmittal.transmittal_create?.Note
      ? this.transmittal.transmittal_create?.Note.length
      : 0;
  }

  setFromUser(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_create.From = e.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setToUser(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_create.To = e.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setLetterDate(e: Date | string): void {
    this.LetterDate = new Date(e);
    this.transmittal.transmittal_create.LetterDate =
      this.LetterDate.toISOString();
    this.enableSaveDraft = this.hasChanges();
  }

  setRefernce(e: string): void {
    this.transmittal.transmittal_create.Reference = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setSubject(e: string): void {
    this.transmittal.transmittal_create.Subject = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setOrgnizationReferenceNumber(e: string): void {
    this.transmittal.transmittal_create.OrganizationReferenceNumber = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setDocuemntType(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_create.DocumentTypeId = e?.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setTransmittalDetail(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.transmittal.transmittal_create.TransmittalDetailId = e?.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setNote(e: string): void {
    this.transmittal.transmittal_create.Note = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setRequireResponse(e: boolean): void {
    this.transmittal.transmittal_issued.RequireResponse = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setReceivedToday(e: boolean): void {
    this.transmittal.transmittal_issued.ReceivedToday = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.dueDate = null;
      this.transmittal.transmittal_issued.DueDate = null;
    } else {
      this.dueDate = new Date(e);
      this.transmittal.transmittal_issued.DueDate = convertDateToUTCIOSString(
        this.dueDate,
      );
    }
    this.enableSaveDraft = this.hasChanges();
  }

  setReceivedDate(e: Date | any): void {
    if (_.isNil(e) || e.type === "change") return;
    if (e.target?.value === "") {
      this.receivedDate = null;
      this.transmittal.transmittal_issued.ReceivedDate = null;
    } else {
      this.receivedDate = new Date(e);
      this.transmittal.transmittal_issued.ReceivedDate =
        convertDateToUTCIOSString(this.receivedDate);
    }
    this.enableSaveDraft = this.hasChanges();
  }

  setIsConfidential(e: boolean): void {
    this.transmittal.transmittal_issued.IsConfidential = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setTags(e: string): void {
    this.transmittal.transmittal_issued.Topics = e;
    this.enableSaveDraft = this.hasChanges();
  }

  // eslint-disable-next-line class-methods-use-this
  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      // console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new TransmittalDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            TransmittalStatus.Draft,
            this.transmittal.Guid ?? this.transmittal.tempId,
          );

          await this.transmittalDocumentsService
            .createTransmittalDocument(fileUploaded)
            .toPromise()
            .then((r: ITransmittalDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item, type);
              this.enableSaveDraft = this.hasChanges();
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    this.updateFileList(e, type, true);
    this.enableSaveDraft = this.hasChanges();
  }

  back(): void {
    if (this.hasChanges()) {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Cancel editing?";
      modalInstance.instance.body = "Your changes will not be saved.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.refreshEntity();
          this.router.navigateByUrl(`${this.contractId}/list`);
        }
      });
    } else {
      this.router.navigateByUrl(`${this.contractId}/list`);
    }
  }

  updateFileList(item: FileModel, type: string, isRemove = false): void {
    let index = -1;

    switch (type) {
      case TransmittalFileType.TransmittalFile:
        index = this.transmittalFile.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.transmittalFile.splice(index, 1);
          }
        } else if (index === -1) {
          this.transmittalFile.push(item);
          this.transmittalFile = this.transmittalFile.slice();
        }

        break;
      case TransmittalFileType.Attachment:
        index = this.attachments.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.attachments.splice(index, 1);
          }
        } else if (index === -1) {
          this.attachments.push(item);
          this.attachments = this.attachments.slice();
        }
        break;
      default:
        break;
    }
    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
    this.isFileNameValid();
  }

  isFileNameValid(): boolean {
    this.transmittalFileNameValid = true;
    this.transmittalFile.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.transmittalFileNameValid = false;
      }
    });
    this.attachmentsNameValid = true;
    this.attachments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.attachmentsNameValid = false;
      }
    });
    return this.transmittalFileNameValid && this.attachmentsNameValid;
  }

  hasFilesUploading(): boolean {
    return (
      this.transmittalFile.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      ) ||
      this.attachments.some((item) => item.Status === UploadStatus.UPLOADING)
    );
  }

  showFileProgressNotification(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);

    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "File upload in progress";
    modalInstance.instance.body =
      "Please wait until all your files have been uploaded";
    modalInstance.instance.type = NotificationType.Information;
    // modalInstance.result.then((result) => {});
  }

  showTransmittalDetail(): boolean {
    return (
      this.transmittal.transmittal_create.DocumentTypeId ===
      documentTypeTransmittalId
    );
  }
}
