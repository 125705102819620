/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-nested-ternary */
import TransmittalRole from "@models/TransmittalRole";
import TransmittalStatus from "@models/TransmittalStatus";
import {
  ITransmittalListItem,
  TransmittalDocumentType,
  TransmittalDetails,
} from "@api";
import {
  getDateFormatted_MMDDYYYY,
  getIcon,
  getLastestIssuedItem,
  getNavigationUrl,
  getStatusText,
  isFinalStep,
} from "@shared/utils";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  documentTypeTransmittalId,
  transmittalAppId,
} from "@shared/staticValue";
import LoginUser from "./loginUser";

interface ReferenceUser {
  name: string;
  org: string;
}

export default class TransmittalListRowItem {
  public Guid: string;

  public TransmittalNumber: string;

  public Subject: string;

  public From: string;

  public To: string;

  public FromOrg: string;

  public ToOrg: string;

  public OrganizationRef: string;

  public ReferenceNumber: string;

  public LetterDate: string;

  public LetterDateLog: string | Date;

  public ReceivedDate: string;

  public DueDate: string;

  public LetterDateSort: string;

  public ReceivedDateSort: string;

  public DueDateSort: string;

  public KeyWords: string[];

  public Status: string;

  public StatusText: string;

  public StatusIcon: string;

  public TransmittalDocType: string | undefined;

  public TransmittalDetail: string | undefined;

  public IsConfidential: string | undefined;

  public BtnText: string;

  public BtnClass: string;

  public BtnUrl: string;

  public IsHidden: boolean;

  public ViewOnly: boolean;

  public HideBtn: boolean;

  public initSort: number;

  constructor(
    item: ITransmittalListItem,
    userRole: string,
    user: LoginUser,
    transmittalDocTypes: TransmittalDocumentType[],
    transmittalDetails: TransmittalDetails[],
    localContractUserService: LocalContractUserService,
  ) {
    this.Guid = item.Guid;
    this.TransmittalNumber = item.TransmittalNumber;
    this.Subject = item.transmittal_create?.Subject;
    const fromUser =
      item.transmittal_create
        ?.correspondence_reference_user_correspondence_reference_userTotransmittal_create_From;
    let fromUserId = "";
    if (fromUser) {
      this.From = fromUser.Name;
      this.FromOrg = fromUser.Organization;
      fromUserId = fromUser.UserId ?? "";
    }
    const toUser =
      item.transmittal_create
        ?.correspondence_reference_user_correspondence_reference_userTotransmittal_create_To;
    let toUserId = "";
    if (toUser) {
      this.To = toUser.Name;
      this.ToOrg = toUser.Organization;
      toUserId = toUser.UserId ?? "";
    }
    this.ReferenceNumber = item.transmittal_create?.Reference;
    this.OrganizationRef =
      item.transmittal_create?.OrganizationReferenceNumber ?? "";
    this.LetterDate = this.getDate(item.transmittal_create?.LetterDate);
    this.ReceivedDate = this.getDate(item.ReceivedDate);
    this.DueDate = this.getDate(item.DueDate);
    this.LetterDateLog = TransmittalListRowItem.getFormattedDate(
      item.transmittal_create?.LetterDate,
    );
    this.LetterDateSort = item.LetterDate ?? "-";
    this.ReceivedDateSort = item.ReceivedDate ?? "-";
    this.DueDateSort = item.DueDate ?? "-";
    const lastIssued = getLastestIssuedItem(item);
    this.KeyWords = TransmittalListRowItem.sortKeyWords(lastIssued?.Topics);
    this.Status = item.Status;
    this.StatusText = getStatusText(
      item.Status,
      userRole === TransmittalRole.DBAdmin,
    );
    this.StatusIcon = getIcon(
      item.Status,
      userRole === TransmittalRole.DBAdmin,
    );
    this.TransmittalDocType = transmittalDocTypes.find((ct) => {
      return ct.Guid === item.transmittal_create.DocumentTypeId;
    })?.Title;
    this.TransmittalDetail =
      item.transmittal_create.DocumentTypeId === documentTypeTransmittalId
        ? transmittalDetails.find((td) => {
            return td.Guid === item.transmittal_create.TransmittalDetailId;
          })?.Title
        : undefined;
    this.BtnText = TransmittalListRowItem.getBtnText(item);
    const isRepresent =
      userRole.includes(TransmittalRole.DocControl) &&
      item.transmittal_issued.length > 0;
    this.BtnClass = TransmittalListRowItem.getBtnClass(
      item.ViewOnly,
      isRepresent,
    );
    this.BtnUrl = getNavigationUrl(item);
    this.IsHidden = false;
    this.ViewOnly = item.ViewOnly;
    const IsConfidential = !lastIssued || lastIssued.IsConfidential;
    this.IsConfidential = lastIssued ? (lastIssued.IsConfidential ? "Yes" : "No") : "N/A";
    const hasRole =
      user.Email === fromUser?.Email ||
      user.Email === toUser?.Email ||
      (item.transmittal_cc_user &&
        item.transmittal_cc_user.filter((cc)=>{return !cc.DeletedBy}).map((cc) => cc.UserId).includes(user.Id));
    const isDB = userRole === TransmittalRole.DBAdmin;
    const originatorId =
      item.SubmittedBy ?? item.transmittal_create.UpdatedBy ?? "";
    const fromOrToDB = [originatorId, fromUserId, toUserId].some((id) => {
      const role = localContractUserService
        .getUserById(id)
        ?.contract_user_application_role?.find((a) => {
          return a.ApplicationId === transmittalAppId;
        }).application_role?.Role;
      return role === TransmittalRole.DBAdmin;
    });
    if (isDB && fromOrToDB) {
      this.HideBtn = false;
    } else {
      this.HideBtn =
        this.ViewOnly === true && IsConfidential !== false && !hasRole;
    }

    this.initSort = TransmittalListRowItem.getInitSort(
      this.ViewOnly,
      isRepresent,
    );
  }

  static getBtnText(item: ITransmittalListItem): string {
    const view = "View";
    const review = "Review";
    const edit = "Edit";
    if (item.ViewOnly === true || isFinalStep(item.Status)) {
      return view;
    }
    if (item.Status === TransmittalStatus.Draft) {
      return edit;
    }
    return review;
  }

  static getBtnClass(viewonly: boolean, isRepresent: boolean): string {
    return viewonly
      ? "btn outline lg blue"
      : isRepresent
      ? "btn solid lg altBottonColor"
      : "btn solid lg blue";
  }

  static getInitSort(viewonly: boolean, isRepresent: boolean): number {
    return viewonly ? 0 : isRepresent ? 1 : 2;
  }

  // static sortKeyWords(values?: string): string | undefined {
  //   if (values) {
  //     return values.trim().split(",").sort().join(", ");
  //   }
  //   return undefined;
  // }
  static sortKeyWords(values?: string): string[] {
    if (values) {
      return values.trim().split(",").sort();
    }
    return [];
  }

  getDate(date?: string | null): string {
    if (!date) {
      return "-";
    }
    const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
  
    return getDateFormatted_MMDDYYYY(tempDate);
  }

  static getFormattedDate(date: string): string | Date {
    if (!date) return "-";
    return new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
  }
}
