import { FileModel } from "@aecom/core";
import { ITransmittalDocumentDownload, ITransmittalListItem } from "@api";
import RowItem from "@shared/selectUser/table-row";
import {
  generateUserRowItem,
  getDate,
  getFilesByType,
  getFormatedDate,
  getLastestIssuedItem,
  isFinalStep,
} from "@shared/utils";
import TransmittalFileType from "./TransmittalFileType";
import TransmittalStatus from "./TransmittalStatus";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";

export default class TransmittalView {
  public transmittalNumber: string;

  public replyBy: string;

  public replyDate: string;

  public letterDate: string;

  public response: string;

  public referenceId: string;

  public note: string;

  public showResult: boolean;

  public noResponse: boolean;

  public ccUsersTableData: RowItem[] = [];

  public ccUsersTableRows: RowItem[] = [];

  public transmittalFile: FileModel[] = [];

  public attachments: FileModel[] = [];

  constructor(
    item: ITransmittalListItem,
    docs: ITransmittalDocumentDownload[],
    users: IContractUserWithUserInfo[],
  ) {
    this.showResult = isFinalStep(item.Status);
    this.noResponse = item.Status === TransmittalStatus.Filed;
    if (this.showResult) {
      if (this.noResponse) {
        this.noResponsePrepare(item);
      } else {
        // this.responseRrepare(item, docs, users);
      }
    }
  }

  private noResponsePrepare(item: ITransmittalListItem): void {
    const none = "N/A";
    const lastIssued = getLastestIssuedItem(item);
    if (lastIssued && lastIssued.RequireResponse === false) {
      this.note = lastIssued.Note ?? none;
    }
  }

  // private responseRrepare(
  //   item: ITransmittalListItem,
  //   docs: ITransmittalDocumentDownload[],
  //   users: IContractUserWithUserInfo[],
  // ): void {
  //   const none = "N/A";
  //   const lastIssued = getLastestIssuedItem(item);
  //   this.transmittalNumber = item.TransmittalNumber;
  //   if (
  //     item.transmittal_response &&
  //     item.transmittal_response.length === 1
  //   ) {
  //     const replyUser = users.find((u) => {
  //       return u.UserId === lastIssued?.ManagerId;
  //     });
  //     this.replyBy = `${item.TransmittalNumber}R | Responded by ${
  //       replyUser?.UserInfo?.displayName ?? " - "
  //     } | ${replyUser?.UserInfo?.companyName ?? " - "}`;
  //     this.replyDate = item.transmittal_response[0].DateUpdated
  //       ? getFormatedDate(item.transmittal_response[0].DateUpdated)
  //       : none;
  //     this.letterDate = item.transmittal_response[0].LetterDate
  //       ? getDate(item.transmittal_response[0].LetterDate)
  //       : none;
  //     this.response = item.transmittal_response[0].Response ?? none;
  //     this.referenceId = item.transmittal_response[0].ReferenceId ?? "-";
  //     const ccUserIds = item.transmittal_cc_user.map((cc) => cc.UserId);
  //     this.ccUsersTableData = users
  //       .filter((user) => {
  //         return ccUserIds.includes(user.UserId);
  //       })
  //       .map((u) => {
  //         return generateUserRowItem<IContractUserWithUserInfo>(u);
  //       });
  //     const documentForThis = docs.filter((doc) => {
  //       return doc.ReferenceId === item.transmittal_response[0].Guid;
  //     });
  //     this.transmittalFile = getFilesByType(
  //       documentForThis,
  //       TransmittalFileType.TransmittalFile,
  //     );
  //     this.attachments = getFilesByType(
  //       documentForThis,
  //       TransmittalFileType.Attachment,
  //     );
  //   }
  // }
}
