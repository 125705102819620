<div class="container-fluid" *ngIf="transmittal">
  <div class="row">
    <div class="col-12">
      <div class="page-header">
        <div class="col-6" style="padding: 0; display: flex">
          <div class="header-title">
            <p>
              <a [routerLink]="['/' + contractId + '/list']"> Transmittal List &gt; </a>
              <span> {{ transmittal.Guid ? "Draft Transmittal" : "Create New Transmittal" }}</span>
            </p>

            <h1>
              <i class="icon icon-lg icon-pencil"></i>

              <span style="text-transform: uppercase">{{ title }}</span>
            </h1>
          </div>
        </div>

        <div class="col-6" style="padding: 0">
          <div class="header-actions">
            <aecom-button data-cy="back" innerClass="btn lg outline orange" (click)="back()"> back </aecom-button>

            <aecom-button
              [theme]="'light'"
              [disabled]="!enableSaveDraft ? true : null"
              innerClass="btn lg solid green"
              (click)="saveDraft()"
            >
              save draft
            </aecom-button>

            <aecom-button data-cy="submit" [theme]="'light'" innerClass="btn lg solid blue" (click)="submit()"> submit </aecom-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <aecom-form columns="12">
        <aecom-form-group
          title="General"
          columnSpan="6"
          columns="2"
          style="display: block; margin-bottom: 10px; min-height: 60%"
        >
          <aecom-form-item [label]="'From (Originator)'" [required]="true" columnSpan="1">
            <aecom-dropdown
            data-cy="from"
              [theme]="'light'"
              [autocomplete]="true"
              placeholder="Select"
              [data]="fromList"
              [selectedId]="transmittal.transmittal_create.From"
              [validate]="attemptToIssue && !transmittal.transmittal_create.From"
              (ngValueChange)="setFromUser($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'To (Recipient)'" [required]="true" columnSpan="1">
            <aecom-dropdown
            data-cy="to"
              [theme]="'light'"
              [autocomplete]="true"
              placeholder="Select"
              [data]="toList"
              [selectedId]="transmittal.transmittal_create.To"
              [validate]="attemptToIssue && !transmittal.transmittal_create.To"
              (ngValueChange)="setToUser($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'Issue Date'" [required]="true" columnSpan="1">
            <aecom-date
              data-cy="letterDate"
              [theme]="'light'"
              [ngValue]="LetterDate"
              [notEmpty]="true"
              (change)="setLetterDate($event)"
              [validate]="!LetterDate && attemptToIssue"
            ></aecom-date>

            <div
              class="error-message"
              *ngIf="!LetterDate && attemptToIssue"
            >
              Please choose issue date.
            </div>
          </aecom-form-item>
          <aecom-form-item [label]="'Organization Reference Number'" columnSpan="1">
            <aecom-textbox
            data-cy="referenceNumber"
              [theme]="'light'"
              [(ngValue)]="transmittal.transmittal_create.OrganizationReferenceNumber"
              (ngValueChange)="setOrgnizationReferenceNumber($event)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Document Type'" [required]="true" columnSpan="1" *ngIf="!isDB">
            <aecom-dropdown
            data-cy="documentType"
              [theme]="'light'"
              placeholder="Select"
              [data]="documentTypeList"
              [selectedId]="transmittal.transmittal_create.DocumentTypeId"
              [validate]="attemptToIssue && !transmittal.transmittal_create.DocumentTypeId"
              (ngValueChange)="setDocuemntType($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'Transmittal Detail'" columnSpan="1" *ngIf="!isDB && showTransmittalDetail()">
            <aecom-dropdown
            data-cy="transmittalDetail"
              [theme]="'light'"
              placeholder="Select"
              [data]="transmittalDetailList"
              [selectedId]="transmittal.transmittal_create.TransmittalDetailId"
              (ngValueChange)="setTransmittalDetail($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'Project Reference'" [required]="true" columnSpan="2" [property]="getReferenceLength() + '/' + referenceMaxLength + ' characters'">
            <aecom-textbox
              data-cy="reference"
              rows="3"
              [notEmpty]="true"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="referenceMaxLength"
              [(ngValue)]="transmittal.transmittal_create.Reference"
              (ngValueChange)="setRefernce($event)"
              [validate]="attemptToIssue && (!transmittal.transmittal_create.Reference || transmittal.transmittal_create.Reference.trim()==='' || getReferenceLength() > referenceMaxLength)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Subject/Title'" [required]="true" columnSpan="2" [property]="getSubjectLength() + '/' + subjectMaxLength + ' characters'">
            <aecom-textbox
              data-cy="subject"
              rows="3"
              [notEmpty]="true"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="subjectMaxLength"
              [(ngValue)]="transmittal.transmittal_create.Subject"
              (ngValueChange)="setSubject($event)"
              [validate]="attemptToIssue && (!transmittal.transmittal_create.Subject || transmittal.transmittal_create.Subject.trim()==='' || getSubjectLength() > subjectMaxLength)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item columnSpan="1" [required]="true" [label]="'Transmittal File'">
            <aecom-upload
              data-cy="transmittalFile"
              [isNewStyle]="true"
              [multiFiles]="true"
              [canDelete]="true"
              [data]="transmittalFile"
              [theme]="'light'"
              (ngValueChange)="importFile($event, transmittalFileType.TransmittalFile)"
              (ngDeleteChange)="deleteFile($event, transmittalFileType.TransmittalFile)"
            ></aecom-upload>
            <div class="error-message" *ngIf="transmittalFile.length === 0 && attemptToIssue">Required Field</div>
            <div class="error-message" *ngIf="!transmittalFileNameValid">
              File Name cannot include special characters other than '-', '_', '.', '@'
            </div>
          </aecom-form-item>

          <aecom-form-item columnSpan="1" [label]="'Attachments'">
            <aecom-upload
              data-cy="attachments"
              [isNewStyle]="true"
              [multiFiles]="true"
              [canDelete]="true"
              [data]="attachments"
              [theme]="'light'"
              (ngValueChange)="importFile($event, transmittalFileType.Attachment)"
              (ngDeleteChange)="deleteFile($event, transmittalFileType.Attachment)"
            ></aecom-upload>
            <div class="error-message" *ngIf="!attachmentsNameValid">
              File Name cannot include special characters other than '-', '_', '.', '@'
            </div>
          </aecom-form-item>
        </aecom-form-group>
        <aecom-form-group
          *ngIf="isDB"
          columnSpan="6"
          columns="2"
          title="CC"
          style="position: relative"
        >
          <div style="width: 100%; grid-column: span 2; height: 180px" class="noTableFilter">
            <aecom-table
              [ngValue]="ccUsersTableData"
              (ngValueChange)="ccUsersTableRows = $event"
              [theme]="'light'"
              innerClass="list"
            >
              <tbody>
                <tr
                  *ngFor="let bRow of ccUsersTableRows"
                  class="assignUser_tr_hover"
                >
                  <ng-container *ngFor="let hCol of tableHeader">
                    <td *ngIf="hCol.Type !== columnType.Hide">
                      <span class="assignUser_td_Item">
                        {{ bRow[hCol.Name] }}
                      </span>
                    </td>
                  </ng-container>

                  <td>
                    <a
                      class="btn"
                      (click)="removeUserClick(bRow)"
                    >
                      <i
                        class="
                          icon
                          icon-social_user_remove
                          icon-orange icon-lg
                        "
                      ></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </aecom-table>

            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
              "
              *ngIf="ccUsersTableData?.length === 0"
            >
              <div class="noItem-body">
                <div>
                  <div
                    class="
                      noItem-icon-row
                      icon
                      icon-social_user_team_big
                      icon-grey icon-xl
                    "
                  ></div>
                </div>

                <div class="noItem-title-row">None</div>
              </div>
            </div>
          </div>
          <div
            style="
              position: absolute;
              top: 5px;
              right: 0;
              cursor: pointer;
            "
          >
            <div
              data-cy="addCCUser"
              class="formGroupManu"
              (click)="openWindowClick()"
            >
              + Add Users
            </div>
          </div>
          <aecom-form-item [label]="'Additional Notes'" columnSpan="2" [property]="getNotesLength() + '/' + notesMaxLength + ' characters'">
            <aecom-textbox
              data-cy="note"
              rows="3"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="notesMaxLength"
              [(ngValue)]="transmittal.transmittal_create.Note"
              (ngValueChange)="setNote($event)"
              [validate]="attemptToIssue && getNotesLength() > notesMaxLength"
            ></aecom-textbox>
          </aecom-form-item>
        </aecom-form-group>
        <aecom-form-group
          *ngIf="!isDB"
          columnSpan="6"
          columns="2"
          title="Processing"
          style="position: relative"
        >
          <aecom-form-item
            data-cy="requireResponse"
            label="Does this Transmittal specifically request a response?"
            columnSpan="1"
          >
            <div class="button-wrapper d-flex">
              <aecom-button
                id="requireResponseYes"
                innerClass="btn solid btn-tab {{
                  transmittal.transmittal_issued.RequireResponse
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setRequireResponse(true)"
              >
                Yes
              </aecom-button>

              <aecom-button
                id="requireResponseNo"
                innerClass="btn solid btn-tab {{
                  !transmittal.transmittal_issued.RequireResponse
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setRequireResponse(false)"
              >
                No
              </aecom-button>
            </div>
          </aecom-form-item>
          <aecom-form-item label="Due Date" columnSpan="1" *ngIf="transmittal.transmittal_issued.RequireResponse; else skipItem">
            <aecom-date
              id="due"
              data-cy="due"
              [weekendsOff]="true"
              [notValidBefore]="today"
              [theme]="'light'"
              [ngValue]="dueDate"
              (change)="setDate($event)"
            >
            </aecom-date>
          </aecom-form-item>
          <aecom-form-item
            data-cy="receivedToday"
            label="Was this Transmittal received today?"
            columnSpan="1"
          >
            <div class="button-wrapper d-flex">
              <aecom-button
                id="receivedToadyYes"
                innerClass="btn solid btn-tab {{
                  transmittal.transmittal_issued.ReceivedToday
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setReceivedToday(true)"
              >
                Yes
              </aecom-button>

              <aecom-button
                id="receivedTodayNo"
                innerClass="btn solid btn-tab {{
                  !transmittal.transmittal_issued.ReceivedToday
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setReceivedToday(false)"
              >
                No
              </aecom-button>
            </div>
          </aecom-form-item>
          <aecom-form-item label="Received Date" columnSpan="1" *ngIf="!transmittal.transmittal_issued.ReceivedToday; else skipItem">
            <aecom-date
              id="receivedDate"
              data-cy="receivedDate"
              [theme]="'light'"
              [ngValue]="receivedDate"
              (change)="setReceivedDate($event)"
              [notValidAfter]="today"
            >
            </aecom-date>
          </aecom-form-item>
          <aecom-form-item
            data-cy="isConfidential"
            label="Is this Transmittal confidential?"
            columnSpan="1"
          >
            <div class="button-wrapper d-flex">
              <aecom-button
                id="isConfidentialYes"
                innerClass="btn solid btn-tab {{
                  transmittal.transmittal_issued.IsConfidential
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setIsConfidential(true)"
              >
                Yes
              </aecom-button>

              <aecom-button
                id="isConfidentialNo"
                innerClass="btn solid btn-tab {{
                  !transmittal.transmittal_issued.IsConfidential
                    ? 'blue'
                    : 'bg-grey-e'
                }}"
                (click)="setIsConfidential(false)"
              >
                No
              </aecom-button>
            </div>
          </aecom-form-item>
          <aecom-form-item columnSpan="1">
          </aecom-form-item>
          <aecom-form-item
            label="Keywords"
            columnSpan="1"
          >
            <bbj-tag id="topic" data-cy="topic" [tagsIn]="transmittal.transmittal_issued.Topics" [allTags]="allTags" (tagsInChange)="setTags($event)"></bbj-tag>
          </aecom-form-item>
          <aecom-form-group
            columnSpan="2"
            columns="2"
            title="CC"
            style="margin-top: 17px; position: relative;"
          >
            <div style="width: 100%; grid-column: span 2; height: 180px" class="noTableFilter">
              <aecom-table
                [ngValue]="ccUsersTableData"
                (ngValueChange)="ccUsersTableRows = $event"
                [theme]="'light'"
                innerClass="list"
              >
                <tbody>
                  <tr
                    *ngFor="let bRow of ccUsersTableRows"
                    class="assignUser_tr_hover"
                  >
                    <ng-container *ngFor="let hCol of tableHeader">
                      <td *ngIf="hCol.Type !== columnType.Hide">
                        <span class="assignUser_td_Item">
                          {{ bRow[hCol.Name] }}
                        </span>
                      </td>
                    </ng-container>

                    <td>
                      <a
                        class="btn"
                        (click)="removeUserClick(bRow)"
                      >
                        <i
                          class="
                            icon
                            icon-social_user_remove
                            icon-orange icon-lg
                          "
                        ></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </aecom-table>

              <div
                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                "
                *ngIf="ccUsersTableData?.length === 0"
              >
                <div class="noItem-body">
                  <div>
                    <div
                      class="
                        noItem-icon-row
                        icon
                        icon-social_user_team_big
                        icon-grey icon-xl
                      "
                    ></div>
                  </div>

                  <div class="noItem-title-row">None</div>
                </div>
              </div>
            </div>
            <div
              style="
                position: absolute;
                top: 5px;
                right: 0;
                cursor: pointer;
              "
            >
              <div
                data-cy="addCCUser"
                class="formGroupManu"
                (click)="openWindowClick()"
              >
                + Add Users
              </div>
            </div>
            <aecom-form-item [label]="'Additional Notes'" columnSpan="2" [property]="getNotesLength() + '/' + notesMaxLength + ' characters'">
              <aecom-textbox
                data-cy="note"
                rows="3"
                [theme]="'light'"
                [isMultiLine]="true"
                [maxlength]="notesMaxLength"
                [(ngValue)]="transmittal.transmittal_create.Note"
                (ngValueChange)="setNote($event)"
                [validate]="attemptToIssue && getNotesLength() > notesMaxLength"
              ></aecom-textbox>
            </aecom-form-item>
          </aecom-form-group>
        </aecom-form-group>
      </aecom-form>
    </div>
  </div>
</div>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow" (clickOutside)="closeWindow()">
    <select-user
      data-cy="selectUser"
      [tableData]="tableRows"
      [title]="'ADD CC'"
      (close)="closeWindow($event)"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>

<ng-template #skipItem>
  <aecom-form-item columnSpan="1"></aecom-form-item>
</ng-template>