import { IMenuItem } from "@aecom/core";


// application table
export const transmittalAppId = "8a011303-0baf-11ea-917a-027e1b1809fa";

export const transmittalDBRoldId = "8a013230-0baf-11ea-917a-027e1b1809fa";
export const transmittalDocControlRoleId = "8a013231-0baf-11ea-917a-027e1b1809fa";
export const transmittalManagerRoleId = "8a013232-0baf-11ea-917a-027e1b1809fa";

// export const transmittalAppId = "8a011297-0baf-11ea-917a-027e1b1809fa";

// export const transmittalDBRoldId = "8a013215-0baf-11ea-917a-027e1b1809fa";
// export const transmittalDocControlRoleId = "8a013216-0baf-11ea-917a-027e1b1809fa";
// export const transmittalManagerRoleId = "8a013217-0baf-11ea-917a-027e1b1809fa";

export const defaultDocumentTypeIdForDB = "6bbc5277-0a68-420a-bf0c-2fb687392ca8";

export const documentTypeTransmittalId = "df8506ef-3487-40d9-9f33-25e696e91fae";

export const NA = "N/A";

export const productName = "Transmittal";

export const productSubtitle = "";

export const productMaintitle = "NYC BBJ PROGRAM";

export const appitems: IMenuItem[] = [
  {
    name: "Support",
    icon: "icon-action_app_support",
    id: "support",
    externalurl:
      "https://apps.powerapps.com/play/c04c6371-a3a6-4b25-a94c-9fe7fd35a16b?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a",
  },
];