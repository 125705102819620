<div *ngIf="data" class="grid-container noTableFilter timelineTable">
  <table class="timelineTable">
    <thead>
      <tr>
        <th id="sequence">#</th>
        <th id="status">Step</th>
        <th id="modifiedBy">Modified By</th>
        <th id="updates" style="width: 40%;">Updates</th>
        <th id="submitted">Time Submitted</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of data; index as i">
        <tr>
          <td>
            <div style="display: flex; position: relative;">
              <div *ngIf="row.Collapse!==undefined" style="position: absolute; left:-18px; cursor: pointer;" [ngStyle]="{'top':row.Collapse ? '-15px' : '-5px'}" (click)="row.Collapse = !row.Collapse">
                <i [ngClass]="row.Collapse ? 'icon-action_sort_desc' : 'icon-action_sort_asc'" class="icon icon-blue icon-md" style="background-color: #ccc;"></i>
              </div>
              <div class="circle">
                <div style="margin: 0 auto">{{ i+1 }}</div>
              </div>
            </div>
            
          </td>
          <td>{{ row.Status }}</td>
          <td>{{ getUserName(row.SubmittedBy) }}</td>
          <td>
            <div class="updateSession" *ngIf="row.showItems.length > 0; else notProvide">
              <ng-container *ngFor="let item of row.showItems;">
                <ng-container 
                    [ngTemplateOutlet]="lineItem" 
                    [ngTemplateOutletContext]="{item:item}">
                </ng-container>
              </ng-container>
              <ng-container *ngIf="row.collapseItems.length > 0;">
                <ng-container *ngIf="!row.Collapse; else ellipsis">
                  <ng-container *ngFor="let item of row.collapseItems;">
                    <ng-container 
                        [ngTemplateOutlet]="lineItem" 
                        [ngTemplateOutletContext]="{item:item}">
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </td>
          <td>{{ getFormatedDateAndTime(row.DateReceived) }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #notProvide>
  <div>-</div>
</ng-template>
<ng-template #blank>
  <div></div>
</ng-template>
<ng-template #ellipsis>
  <div>...</div>
</ng-template>
<ng-template #lineItem let-item="item">
  <div *ngIf="item.type === 'bool'">
    <div style="width: 60%;">{{item.name}}</div>
    <div>{{item.value ? 'Yes':'No'}}</div>
  </div>
  <div *ngIf="item.type === 'date'">
    <div style="width: 60%;">{{item.name}}</div>
    <div *ngIf="item.value !== ''; else blank">
      {{ getFormatedDateMMDDYYYYY(item.value) }}
    </div>
  </div>
  <div *ngIf="item.type === 'key'">
    <div style="margin-right: 5px;">{{item.name}}</div>
    <div style="display: flex; flex-wrap: wrap" *ngIf="item.value.add.length > 0 || item.value.delete.length > 0; else notProvide">
      <ng-container *ngIf="item.value.delete.length > 0">
        <span>-</span>
        <ng-container *ngFor="let tag of item.value.delete">
          <div style="position: relative; margin-right: 6px; margin-bottom: 6px;">
            <div class="tag">
              {{tag}}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="item.value.add.length > 0">
        <span>+</span>
        <ng-container *ngFor="let tag of item.value.add">
          <div style="position: relative; margin-right: 6px; margin-bottom: 6px;">
            <div class="tag">
              {{tag}}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div  *ngIf="item.type === 'cc'">
    <div style="margin-right: 5px;">{{item.name}}</div>
    <div>
      <ng-container *ngIf="item.value.delete.length > 0">
        <span>-</span>
        <ng-container *ngFor="let dCC of item.value.delete; index as k">
          <span>
            {{ getUserName(dCC) }}{{k===item.value.delete.length-1 ? "; " : ", "}}
          </span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="item.value.add.length > 0">
        <span>+</span>
        <ng-container *ngFor="let aCC of item.value.add; index as k">
          <span>
            {{ getUserName(aCC) }}{{k===item.value.add.length-1 ? ";" : ","}}
          </span>
        </ng-container>
      </ng-container>
      
    </div>
  </div>
</ng-template>