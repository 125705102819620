<ng-container>
  <transmittalHeader [transmittal]="entity" [IsDbAdmin]="isDBAdmin"></transmittalHeader>
  <aecom-tabs class="cusTab" [defaultTab]="defaultTab">
    <aecom-tab title="details">
      <div style="overflow-y: auto; overflow-x: hidden">
        <transmittalDetail
          [transmittal]="entity"
          [isDBAdmin]="isDBAdmin"
          [documents]="docs"
        ></transmittalDetail>
      </div>
    </aecom-tab>
    <aecom-tab title="processing" *ngIf="!isDBAdmin">
      <transmittalProcessing [transmittal]="entity"></transmittalProcessing>
    </aecom-tab>
  </aecom-tabs>
</ng-container>