import { UUID } from "angular2-uuid";
import { ITransmittalCreateStepItem, ITransmittalListItem } from "@api";
import { getLastestIssuedItem } from "@shared/utils";
import TransmittalUpdateBase from "./TransmittalUpdateBase";
import TransmittalCreateBase from "./transmittalCreateBase";
import TransmittalIssueBase from "./transmittalIssueBase";

export default class TransmittalCreate
  extends TransmittalUpdateBase
  implements ITransmittalCreateStepItem
{
  public transmittal_create: TransmittalCreateBase;

  public transmittal_issued?: TransmittalIssueBase;

  public transmittal_cc_user?: string[];

  public tempId?: string;

  constructor(
    contractId: string,
    isDB: boolean,
    item?: ITransmittalListItem,
    dbUserId?: string,
  ) {
    super(contractId, item);

    this.transmittal_create = item?.transmittal_create
      ? new TransmittalCreateBase(isDB, item.transmittal_create, dbUserId)
      : new TransmittalCreateBase(isDB, undefined, dbUserId);
    if (!isDB) {
      const lastIssued = getLastestIssuedItem(item);
      this.transmittal_issued = lastIssued
        ? new TransmittalIssueBase(lastIssued)
        : new TransmittalIssueBase();
    }
    if (!item) {
      this.tempId = UUID.UUID();
    }
    if (item?.transmittal_cc_user) {
      this.transmittal_cc_user = item.transmittal_cc_user
        .filter((cc) => {
          return cc.DeletedBy === null;
        })
        .map((user) => {
          return user.UserId;
        });
    } else {
      this.transmittal_cc_user = [];
    }
  }
}
